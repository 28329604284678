import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';

var sender_id = '';
var platform = '';
var os_version = '';
var app_version = '';

if(Capacitor.isNativePlatform()){

  console.log("Capacitor.isNativePlatform: true");

  const osPlatform = Capacitor.getPlatform(); // Retorna 'android' o 'ios'

  if (window.Laravel && !window.Laravel.isLoggedIn) {
    // PASO 1: Solicita permiso para notificaciones
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // PASO 2: Regístrate con APNs
        PushNotifications.register();
      } else {
        console.log('Permisos denegados');
      }
    });

    // PASO 3: Escucha el evento de registro exitoso con APNs
    PushNotifications.addListener('registration', (token) => {

      // Get FCM token instead of the APN one returned by Capacitor
      FCM.getToken()
        .then(r => {
          sender_id = r.token;

          Device.getInfo().then(info => {

            if (info.platform === 'android') {
              platform = 1;
            } else if (info.platform === 'ios') {
              platform = 2;
            }

            os_version = info.osVersion;
            app_version = "2.00";

            $(document).on('shown.bs.modal', '#modal-login', function (event) {
              // Aquí, el modal está en el DOM, y los elementos deberían existir
              const senderInput = document.querySelector('input[name="sender_id"]');
              if (senderInput) {
                senderInput.value = sender_id;
              }
              const platformInput = document.querySelector('input[name="platform"]');
              if (platformInput) {
                platformInput.value = platform;
              }
              const osVersionInput = document.querySelector('input[name="os_version"]');
              if (osVersionInput) {
                osVersionInput.value = os_version;
              }
              const appVersionInput = document.querySelector('input[name="app_version"]');
              if (appVersionInput) {
                appVersionInput.value = app_version;
              }

            });        

          });

        })
        .catch(err => {
          // alert(err);
          console.log(err);
        });

    });
  }

  // Delete the old FCM token and get a new one
  // FCM.refreshToken()
  //   .then(r => alert(`Token ${r.token}`))
  //   .catch(err => console.log(err));

  // // Remove FCM instance
  // FCM.deleteInstance()
  //   .then(() => alert(`Token deleted`))
  //   .catch(err => console.log(err));

  if (window.Laravel && !window.Laravel.isLoggedIn) {
    PushNotifications.addListener('registrationError', (error) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });
  }

  if (window.Laravel && window.Laravel.isLoggedIn) {
    PushNotifications.addListener('pushNotificationReceived', (notification) => {
      console.log('Push received: ' + JSON.stringify(notification));
    });
  }

  PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
    console.log('Push action performed: ' + JSON.stringify(notification));
  });

  // Enable the auto initialization of the library
  FCM.setAutoInit({ enabled: true }).then(() => console.log(`Auto init enabled`));

  // Check the auto initialization status
  FCM.isAutoInitEnabled().then(r => {
    console.log('Auto init is ' + (r.enabled ? 'enabled' : 'disabled'));
  });

} else {
  console.log(" WEB 1 !!!!! ");

  import('firebase/app').then(async ({ initializeApp }) => {
    const { getMessaging, getToken, onMessage } = await import('firebase/messaging');

    const firebaseConfig = {
      apiKey: "AIzaSyC1Af2c-w9tRkCSKw3nNUEYZ6uI0Sa7Stw",
      authDomain: "peralba-pro.firebaseapp.com",
      projectId: "peralba-pro",
      storageBucket: "peralba-pro.firebasestorage.app",
      messagingSenderId: "448093692026",
      appId: "1:448093692026:web:80542c3810c4a31c3b5bc3",
      measurementId: "G-8Z2QWEHKT2"
    };

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    if (window.Laravel && !window.Laravel.isLoggedIn) {
      try {
        console.log("Request permissions");
        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
          const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
          const token = await getToken(messaging, {
            vapidKey: "BBj8TFURmlNsqEHqrA39kHb52Gd9ovN7U_UsSunxyTdd0eFdZUC84coJ7LGBiI8zFsIeJUTyBrtUbeP7hy0T4SA",
            serviceWorkerRegistration: registration
          });

          console.log('Token FCM Web:', token);
          sender_id = token;
          platform = 3; // Web
          os_version = navigator.userAgent;
          app_version = "2.00";

          $(document).on('shown.bs.modal', '#modal-login', function () {
            const senderInput = document.querySelector('input[name="sender_id"]');
            if (senderInput) senderInput.value = sender_id;

            const platformInput = document.querySelector('input[name="platform"]');
            if (platformInput) platformInput.value = platform;

            const osVersionInput = document.querySelector('input[name="os_version"]');
            if (osVersionInput) osVersionInput.value = os_version;

            const appVersionInput = document.querySelector('input[name="app_version"]');
            if (appVersionInput) appVersionInput.value = app_version;
          });
        } else {
          console.log('Permiso para notificaciones denegado');
        }

      } catch (error) {
        console.error("Error al registrar notificaciones en web:", error);
      }
    }

    if (window.Laravel && window.Laravel.isLoggedIn) {
      onMessage(messaging, (payload) => {
        console.log("Mensaje recibido en primer plano (web):", payload);
        alert(`${payload.notification?.title}\n${payload.notification?.body}`);
      });
    }
  });



}

